import React, { useState, useEffect } from "react";
import TranslationFile from "../Translation/Translation.json";
import CookieConsent, { Cookies } from "react-cookie-consent";
import "./Cookie.css";

export default function Cookie() {
  let userSelectedLanguage = localStorage.getItem("selectedLanguage");
  const [cookieMsg, setCookieMsg] = useState("");

  useEffect(() => {
    if (userSelectedLanguage == null) {
      userSelectedLanguage = "sw";
    }

    setCookieMsg(TranslationFile.cookiesText[userSelectedLanguage]);
  }, []);

  return (
    <CookieConsent
      location="bottom"
      buttonText="OK"
      cookieName="RBLCookieConsent"
      buttonStyle={{
        padding: "12px 32px",
        "border-radius": "4px",
        outline: "none",
        "font-size": "14px",
        "font-weight": "500",
        color: "white",
        backgroundColor: "#ff5f00",
        border: "1px solid #ff5f00",
      }}
      expires={150}
      enableDeclineButton
    >
      {cookieMsg}
    </CookieConsent>
  );
}
