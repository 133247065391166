import React, { Component } from "react";
import HeaderNavBar from "../HeaderNavBar/HeaderNavBar";
import ScrolltoTop from "../ScrollTop/ScrollTop";
import Footer from "../Footerpage/Footer";
import TranslationFile from "../Translation/Translation.json";
import "./Credits.css";

let userSelectedLanguage;

class Credits extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  scrollToContactUs() {
    this.props.callBack();
  }

  componentWillMount() {
    userSelectedLanguage = localStorage.getItem("selectedLanguage");
    window.scrollTo(0, 0);
  }

  callback = () => {
    this.setState({
      reRender: true,
    });
  };

  render() {
    return (
      <div>
        <HeaderNavBar isCorpPortal={true} callBack={this.callback} />
        <div className="ID_Credits">
          <br></br>
          <br></br>

          <div
            style={{
              textTransform: "uppercase",
              fontWeight: 500,
              fontSize: "30px",
              textAlign: "left",
            }}
          >
            <span style={{ color: "red" }}>Credits</span>
          </div>
          <br></br>
          <div>
            Image by
            <a
              href="https://www.freepik.com/free-photo/blue-print-with-laptop-copy-space_6413502.htm#from_view=detail_alsolike"
              target="_blank"
            >
              Freepik
            </a>
            <br></br>
            Image by
            <a
              href="https://www.freepik.com/free-photo/blue-print-with-laptop-copy-space_6413502.htm#from_view=detail_alsolike%22%3EFreepik%3C/a%3E"
              target="_blank"
            >
              Freepik
            </a>
            <br></br>
            <a
              href="https://www.freepik.com/free-photo/thoughtful-businessman-sitting-with-open-laptop-computer-looking-worried-while-thinking-about-planning-top-view_1202129.htm?query=accounting#from_view=detail_alsolike%22%3EImage"
              target="_blank"
            >
              Image by snowing
            </a>
            on Freepik
          </div>
        </div>
        <ScrolltoTop />
        <Footer />
      </div>
    );
  }
}

export default Credits;
